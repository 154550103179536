import React from 'react';
import Footer from 'components/core/Footer/Footer';
import Navbar from 'components/core/Navbar/Navbar';
import PrivacyPolicySection from './PrivacyPolicySection';
import { privacyPolicyData } from './privacyPolicyData';

const PrivacyPolicy = () => {
  return (
    <section>
      <div className="min-h-[calc(100vh - 420px)]">
        <div className="">
          <Navbar />
          <div className="px-[5.25rem]">
            <h1 className="text-[4.25rem] text-typography font-bold mt-16">
              Privacy Policy
            </h1>
            {privacyPolicyData.map((section, index) => (
              <PrivacyPolicySection
                key={index}
                title={section.title}
                index={index + 1}
                description={section.description}
                subSections={section.subSections}
              />
            ))}

            <p className="text-typography text-lg font-bold mt-2">
              DriveLife Support
            </p>
            <p className="text-typography text-lg font-normal">
              <span className="font-bold">Email:</span> support@drivelife.ai
            </p>
            <p className="text-typography text-lg font-normal">
              <span className="font-bold">Phone:</span>
              [+15146198800]
            </p>
            <p className="text-typography-80 text-lg font-normal mt-6 mb-16">
              Thank you for using DriveLife. Your privacy is important to us!
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default PrivacyPolicy;
