import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { GetStartedFormData } from 'components/GetStartedModal/schema';

export type TextareaProps = {
  name: keyof GetStartedFormData;
  label: string;
  wrapperClassName?: string;
  textareaProps?: React.InputHTMLAttributes<HTMLTextAreaElement>;
  error?: string;
  register: UseFormRegister<GetStartedFormData>;
};
const Textarea: React.FC<TextareaProps> = ({
  name,
  label,
  textareaProps,
  wrapperClassName,
  error,
  register,
}: TextareaProps) => {
  return (
    <div className={wrapperClassName}>
      {label && (
        <label htmlFor={name} className="text-sm font-medium text-typography">
          {label}
        </label>
      )}

      <textarea
        id={name}
        className="mt-1.5 border border-background-20 rounded-lg w-full py-4 pl-6 placeholder:text-base placeholder:font-normal text-typography font-normal resize-none"
        {...textareaProps}
        {...register(name)}
        rows={4}
      />
      <p className="text-xs font-normal text-error mt-2">{error}</p>
    </div>
  );
};

export default Textarea;
