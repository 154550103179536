import React from 'react';
import Button from 'components/core/Button/Button';
import Product from './components/Product';
import PageTitle from 'components/core/PageTitle/PageTitle';
import PageDescription from 'components/core/PageDescription/PageDescription';
import Label from 'components/core/Label/Label';
import { useGetStartedModalContext } from 'components/GetStartedModal/GetStartedModalContext';

const OurProducts = () => {
  const { setIsGetStartedModalOpen } = useGetStartedModalContext();

  return (
    <div className="flex items-center justify-center" id="our-products">
      <div className="py-[7.5rem] px-[5.25rem]">
        <Label className="mx-auto flex">Our Products</Label>
        <div className="flex mt-6">
          <section>
            <PageTitle
              title="Streamline Your Dealership"
              outlinedPart="Operations"
              className="text-center"
            />
            <PageDescription
              text="Elevate your dealership's efficiency and productivity with our
              advanced lot management system"
              className="mt-6 max-w-[32rem]  text-center mx-auto"
            />
            <Button
              type="primary"
              className="mt-8 mx-auto"
              size="lg"
              onClick={() => setIsGetStartedModalOpen(true)}
            >
              Get Started
            </Button>
            <div className="mt-[3.875rem] flex">
              <Product
                title="Comprehensive Web Dashboard"
                description="Manage your entire inventory with ease using our intuitive web
                  dashboard. Track vehicle locations, monitor sales data, and
                  optimize lot organization all from a single platform."
                image={{
                  src: '/images/our-products/laptop-mockup.png',
                  alt: 'Laptop Mockup',
                }}
                imageContainerClassName="mt-[8.5rem]"
              />

              <Product
                title="Mobile Application for On-the-Go Management"
                description="Receive real-time updates, access vehicle details, and ensure
                  seamless communication within your team."
                image={{
                  src: '/images/our-products/iphone-mockup.png',
                  alt: 'iPhone Mockup',
                }}
                className="ml-5"
                imageContainerClassName="mt-[4.5rem]"
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default OurProducts;
