export const termsOfServiceData: {
  title: string;
  description: string[];
}[] = [
  {
    title: 'Introduction',
    description: [
      `Welcome to DriveLife! These terms and conditions outline the rules and regulations for the use of DriveLife's mobile application, designed to help users locate their vehicles, create routes, and activate theft mode. By downloading or using this app, we assume you accept these terms and conditions. Do not continue to use DriveLife if you do not agree to all the terms and conditions stated on this page.`,
    ],
  },
  {
    title: 'Definitions',
    description: [
      '"App" refers to the DriveLife mobile application.',
      '"User" refers to any individual who downloads, installs, or uses the App.',
      '"Services" refers to the functionalities provided by the App, including vehicle location tracking, route creation, and theft mode activation.',
    ],
  },
  {
    title: 'Use of the App',
    description: [
      'Users must be at least 18 years old to use the App.',
      'The App is provided for personal use only. Commercial use is strictly prohibited.',
      'Users are responsible for maintaining the confidentiality of their account information and are liable for all activities that occur under their account.',
    ],
  },
  {
    title: 'Vehicle Location Tracking',
    description: [
      'The App allows users to track the location of their vehicle. Users must ensure they have the necessary permissions and rights to track the vehicle.',
      'DriveLife is not responsible for any inaccuracies in vehicle location data due to GPS or other technical limitations.',
    ],
  },
  {
    title: 'Route Creation',
    description: [
      'The App provides a feature to create routes for the user’s vehicle. DriveLife does not guarantee the accuracy, reliability, or completeness of any route information provided.',
      'Users should exercise caution and follow local traffic laws and regulations when using the route creation feature.',
    ],
  },
  {
    title: 'Theft Mode Activation',
    description: [
      'The App includes a theft mode activation feature to help users secure their vehicles. This feature is intended to be an additional security measure and should not replace traditional vehicle security systems.',
      'DriveLife is not responsible for any loss or damage resulting from the use or inability to use the theft mode feature.',
    ],
  },
  {
    title: 'Privacy Policy',
    description: [
      'By using the App, users agree to the collection and use of their data in accordance with the DriveLife Privacy Policy. The policy outlines how we collect, use, and protect user information.',
    ],
  },
  {
    title: 'Intellectual Property Rights',
    description: [
      'DriveLife and its licensors own all intellectual property rights to the App and its content. Users are granted a limited license to use the App in accordance with these terms and conditions.',
    ],
  },
  {
    title: 'Limitation of Liability',
    description: [
      'The App and its services are provided "as is" and "as available" without any warranties, express or implied. DriveLife does not guarantee that the App will be error-free or uninterrupted.',
      'DriveLife will not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or related to the use of the App.',
    ],
  },
  {
    title: 'Changes to Terms and Conditions',
    description: [
      'DriveLife reserves the right to update these terms and conditions at any time. Users are encouraged to review this page periodically for any changes. Continued use of the App after any modifications indicates acceptance of the new terms.',
    ],
  },
  {
    title: 'Governing Law',
    description: [
      'These terms and conditions are governed by and construed in accordance with the laws of the jurisdiction in which DriveLife operates, without regard to its conflict of law provisions.',
    ],
  },
  {
    title: 'Contact Information',
    description: [
      'If you have any questions about these terms and conditions, please contact us at support@drivelife.com.',
    ],
  },
];
