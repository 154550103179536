import React from 'react';
import Button from '../Button/Button';
import NavbarLink from './NavbarLink';
import DriveLifeLogo from 'assets/logos/DriveLifeLogo';
import { useGetStartedModalContext } from 'components/GetStartedModal/GetStartedModalContext';

const Navbar = () => {
  const { setIsGetStartedModalOpen } = useGetStartedModalContext();

  return (
    <div className="px-[5.25rem] py-8 flex justify-between items-center">
      <a href="/">
        <DriveLifeLogo />
      </a>
      <ul className="flex items-center">
        <NavbarLink text="About Us" href="/#about-us" />
        <NavbarLink text="Product" href="/#our-products" className="ml-8" />
        <NavbarLink text="Contact Us" href="/#contact-us" className="ml-8" />
        <Button className="ml-8" onClick={() => setIsGetStartedModalOpen(true)}>
          Get Started
        </Button>
      </ul>
    </div>
  );
};

export default Navbar;
