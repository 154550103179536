import React from 'react';
import classNames from 'classnames';

const Product = ({
  title,
  description,
  image,
  className,
  imageContainerClassName,
}: {
  title: string;
  description: string;
  image: {
    src: string;
    alt: string;
  };
  className?: string;
  imageContainerClassName?: string;
}) => {
  return (
    <section
      className={classNames(
        'p-8 bg-background-10 border border-grey-10 rounded-3xl flex-1 pb-0 shadow-xs',
        className,
      )}
    >
      <h4 className="text-[2rem] text-typography font-bold capitalize text-center">
        {title}
      </h4>
      <p className="text-typography-80 text-base font-normal mt-6 max-w-[32rem] text-center mx-auto">
        {description}
      </p>
      <div
        className={classNames('flex justify-center', imageContainerClassName)}
      >
        <img src={image.src} alt={image.alt} width={520} />
      </div>
    </section>
  );
};

export default Product;
