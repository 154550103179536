import { PrivacySection } from 'utils/types';

export const privacyPolicyData: PrivacySection[] = [
  {
    title: 'Introduction',
    description: `Welcome to DriveLife! We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our mobile application (the "App") which helps users to find their vehicle's location, create routes, and activate theft mode for their vehicle.`,
  },
  {
    title: 'Information We Collect',
    subSections: [
      {
        subtitle: 'Personal Information:',
        description: [
          'Account Information: When you create an account, we collect your name, email address, phone number, and password.',
          'Vehicle Information: We collect information about your vehicle, including make, model, year, and registration number.',
        ],
      },
      {
        subtitle: 'Usage Information:',
        description: [
          'Location Data: We collect real-time location information of your vehicle to provide location tracking and route creation services.',
          'App Usage Data: We collect data on how you interact with our App, including the features you use and the time spent on the App.',
        ],
      },
      {
        subtitle: 'Device Information:',
        description: [
          'Device Details: We collect information about the device you use to access our App, including the device model, operating system, and unique device identifiers.',
        ],
      },
    ],
  },
  {
    title: 'How We Use Your Information',
    subSections: [
      {
        subtitle: 'To Provide and Improve Our Services:',
        description: [
          'Use your location data to help you find your vehicle and create routes.',
          'Activate and manage theft mode for your vehicle.',
          'Improve the functionality and user experience of our App.',
        ],
      },
      {
        subtitle: 'To Communicate with You:',
        description: [
          'Send you updates, security alerts, and support messages.',
          'Respond to your inquiries and provide customer service.',
        ],
      },
      {
        subtitle: 'For Security and Legal Compliance:',
        description: [
          'Monitor and prevent any unauthorized access or use of our services.',
          'Comply with legal obligations and resolve any disputes that may arise.',
        ],
      },
    ],
  },
  {
    title: 'How We Share Your Information',
    subSections: [
      {
        subtitle: 'With Service Providers:',
        description: [
          'We may share your information with third-party service providers who assist us in operating our App, conducting our business, or providing services to you, as long as those parties agree to keep this information confidential.',
        ],
      },
      {
        subtitle: 'For Legal Reasons:',
        description: [
          'We may disclose your information if required to do so by law or in response to valid requests by public authorities.',
        ],
      },
    ],
  },
  {
    title: 'Data Security',
    description:
      'We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no internet or email transmission is ever fully secure or error-free.',
  },
  {
    title: 'Data Retention',
    description:
      'We retain your personal information for as long as necessary to provide you with our services and for legitimate and essential business purposes, such as maintaining the performance of our App, making data-driven business decisions, and complying with legal obligations.',
  },
  {
    title: 'Your Rights',
    description:
      'You have the right to access, correct, or delete your personal information held by us. You can also object to the processing of your data, request us to restrict processing, or request portability of your data.',
  },
  {
    title: 'Changes to This Privacy Policy',
    description:
      'We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective Date" at the top of this Privacy Policy.',
  },
  {
    title: 'Contact Us',
    description:
      'If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:',
  },
];
