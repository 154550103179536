import React from 'react';
import Footer from 'components/core/Footer/Footer';
import Navbar from 'components/core/Navbar/Navbar';
import TermsOfServiceSection from './TermsOfServiceSection';
import { termsOfServiceData } from './termsOfServiceData';

const TermsOfService = () => {
  return (
    <section>
      <div className="min-h-[calc(100vh - 420px)]">
        <div className="">
          <Navbar />
          <div className="px-[5.25rem]">
            <h1 className="text-[4.25rem] text-typography font-bold mt-16">
              Terms of Service
            </h1>
            {termsOfServiceData.map((section, index) => (
              <TermsOfServiceSection
                key={index}
                title={section.title}
                index={index + 1}
                description={section.description}
              />
            ))}

            <p className="text-typography-80 text-md font-bold mt-12 mb-16">
              By using the DriveLife app, you signify your acceptance of these
              terms and conditions. If you do not agree to these terms, please
              do not use our app.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default TermsOfService;
