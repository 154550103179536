export enum BenefitType {
  RealTimeInventoryTracking = 'Real-Time Inventory Tracking',
  ImprovedSalesEfficiency = 'Improved Sales Efficiency',
  EnhancedCustomerExperience = 'Enhanced Customer Experience',
}

export interface PrivacyPolicySubSection {
  subtitle: string;
  description: string[];
}

export interface PrivacySection {
  title: string;
  description?: string;
  subSections?: PrivacyPolicySubSection[];
}

export enum UserCookiePreference {
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}
