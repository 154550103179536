import React from 'react';
import Navbar from 'components/core/Navbar/Navbar';
import Button from 'components/core/Button/Button';
import { useGetStartedModalContext } from 'components/GetStartedModal/GetStartedModalContext';

const Home = () => {
  const { setIsGetStartedModalOpen } = useGetStartedModalContext();

  return (
    <div className="bg-hero-section bg-cover min-h-[1432px]">
      <Navbar />
      <h1 className="text-[4.25rem] text-typography font-bold text-center mt-16 mx-auto">
        Dealership Inventory <br /> Management, Theft Retrieval
      </h1>
      <p className="text-typography-80 text-center mx-auto text-lg font-normal mt-6 max-w-[48rem]">
        Gain complete visibility and an overview of your vehicle inventory,
        enabling quicker sales by making cars and keys easier to locate. Enhance
        customer satisfaction, quickly recover stolen vehicles, and lower
        garagekeeper insurance premiums.
      </p>
      <Button
        type="primary"
        className="mx-auto mt-8"
        size="lg"
        onClick={() => setIsGetStartedModalOpen(true)}
      >
        Get Started
      </Button>
    </div>
  );
};

export default Home;
