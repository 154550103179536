import React from 'react';
import classNames from 'classnames';

const Benefit = ({
  title,
  description,
  isActive,
  className,
  onSelectBenefit,
}: {
  title: string;
  description: string;
  isActive: boolean;
  className?: string;
  onSelectBenefit: () => void;
}) => {
  return (
    <section
      className={classNames(
        'pl-6 hover:cursor-pointer',
        isActive ? 'border-l-[3px] border-primary-80' : 'opacity-60',
        className,
      )}
      onClick={onSelectBenefit}
    >
      <p className="text-2xl text-typography font-bold">{title}</p>
      <p className="text-typography-80 text-base font-normal mt-3 max-w-[27.5rem] font-noto">
        {description}
      </p>
    </section>
  );
};

export default Benefit;
