import React from 'react';

const X = ({ color = 'white' }: { color?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9737 6.7174C20.5093 7.33692 19.9479 7.87733 19.3112 8.31776C19.3112 8.47959 19.3112 8.64142 19.3112 8.81225C19.3163 11.7511 18.1424 14.5691 16.0527 16.6345C13.9629 18.6999 11.1321 19.8399 8.19491 19.7989C6.49685 19.8046 4.82053 19.4169 3.29728 18.6661C3.21514 18.6302 3.16217 18.549 3.16248 18.4593V18.3604C3.16248 18.2313 3.26709 18.1267 3.39613 18.1267C5.06528 18.0716 6.67488 17.4929 7.9972 16.4724C6.48639 16.4419 5.12705 15.5469 4.50146 14.1707C4.46987 14.0956 4.4797 14.0093 4.52743 13.9432C4.57515 13.8771 4.65386 13.8407 4.73511 13.8471C5.19428 13.8932 5.65804 13.8505 6.10106 13.7212C4.43325 13.375 3.18007 11.9904 3.00072 10.2957C2.99435 10.2144 3.03078 10.1357 3.09686 10.0879C3.16293 10.0402 3.2491 10.0303 3.32424 10.062C3.7718 10.2595 4.25495 10.3635 4.7441 10.3676C3.2827 9.40846 2.65147 7.58405 3.20741 5.92622C3.2648 5.76513 3.40267 5.64612 3.57036 5.61294C3.73804 5.57975 3.91082 5.63728 4.02518 5.76439C5.99725 7.86325 8.7069 9.11396 11.5828 9.25279C11.5092 8.95885 11.473 8.65676 11.475 8.35372C11.5019 6.76472 12.4851 5.34921 13.9643 4.76987C15.4434 4.19054 17.1258 4.56203 18.2239 5.71044C18.9723 5.56785 19.6959 5.31645 20.3716 4.96421C20.4211 4.93331 20.4839 4.93331 20.5334 4.96421C20.5643 5.01373 20.5643 5.07652 20.5334 5.12604C20.2061 5.87552 19.6532 6.50412 18.9518 6.92419C19.566 6.85296 20.1694 6.70807 20.7491 6.49264C20.7979 6.45942 20.862 6.45942 20.9108 6.49264C20.9517 6.51134 20.9823 6.54711 20.9944 6.59042C21.0065 6.63373 20.9989 6.68018 20.9737 6.7174Z"
        fill={color}
      />
    </svg>
  );
};

export default X;
