import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import Homepage from 'routes/Homepage/Homepage';
import PrivacyPolicy from 'routes/PrivacyPolicy/PrivacyPolicy';
import TermsOfService from 'routes/TermsOfService/TermsOfService';
import GetStartedModalContext from 'components/GetStartedModal/GetStartedModalContext';
import GetStartedModal from 'components/GetStartedModal/GetStartedModal';

const queryClient = new QueryClient();

function App() {
  const [isGetStartedModalOpen, setIsGetStartedModalOpen] = useState(false);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <GetStartedModalContext.Provider
          value={{ isGetStartedModalOpen, setIsGetStartedModalOpen }}
        >
          <Routes>
            <Route path="/" Component={Homepage} />
            <Route path="/privacy-policy" Component={PrivacyPolicy} />
            <Route path="/terms-of-service" Component={TermsOfService} />
          </Routes>
          {isGetStartedModalOpen && <GetStartedModal />}
          <ToastContainer />
        </GetStartedModalContext.Provider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
