import React from 'react';

const Star = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="star-fill">
        <path
          id="Vector"
          d="M12.0252 2.44141C11.4543 2.44121 10.8713 2.79641 10.526 3.50341L8.40208 7.87611L3.56088 8.56321C2.00398 8.77981 1.49958 10.3072 2.62388 11.4052L6.12198 14.8102L5.30998 19.5892C5.04258 21.1362 6.32568 22.0712 7.71498 21.3382C8.25178 21.0542 11.0119 19.6242 12.0252 19.0892L16.3354 21.3382C17.7263 22.0712 19.0134 21.1372 18.7404 19.5892L17.8971 14.8102L21.3953 11.4052C22.5249 10.3112 22.0465 8.78421 20.4895 8.56321L15.6171 7.87611L13.5244 3.50341C13.1796 2.79611 12.5961 2.44171 12.0252 2.44141Z"
          fill="#3D5BF6"
        />
      </g>
    </svg>
  );
};

export default Star;
