import React from 'react';
import Home from 'components/Home/Home';
import AboutUs from 'components/AboutUs/AboutUs';
import GetStarted from 'components/GetStarted/GetStarted';
import Footer from 'components/core/Footer/Footer';
import OurProducts from 'components/OurProducts/OurProducts';
import OurBenefits from 'components/OurBenefits/OurBenefits';

const Homepage = () => {
  return (
    <section>
      <Home />
      <AboutUs />
      <OurProducts />
      <OurBenefits />
      <GetStarted />
      <Footer />
    </section>
  );
};

export default Homepage;
