import React from 'react';
import DriveLifeLogoWhite from 'assets/logos/DriveLifeLogoWhite';
import Facebook from 'assets/icons/Facebook';
import Instagram from 'assets/icons/Instagram';
import X from 'assets/icons/X';
import LinkedIn from 'assets/icons/LinkedIn';
import FooterLink from './FooterLink';

const Footer = () => {
  return (
    <footer className="py-20 px-16 bg-typography">
      <div className="flex justify-between items-start py-20 border-b border-typography-80">
        <a href="/">
          <DriveLifeLogoWhite width="128" />
        </a>
        <ul className="flex items-center">
          <FooterLink text="About Us" href="/#about-us" />
          <FooterLink text="Product" href="/#our-products" className="ml-8" />
        </ul>

        <section id="contact-us">
          <span className="text-background font-noto font-medium font-lg">
            Contact Us
          </span>
          <div className="flex mt-2">
            <div className="flex flex-col font-medium text-background">
              <span className="mt-2">Email</span>
              <span className="mt-2">Phone</span>
              <span className="mt-2">Address</span>
            </div>
            <div className="flex flex-col ml-8 text-primary">
              <a className="mt-2" href="mailto:support@drivelife.ai">
                support@drivelife.ai
              </a>
              <a className="mt-2" href="tel:+1514619-8800">
                +1 (514) 619-8800
              </a>
              <address className="mt-2 not-italic">
                4651 SHERIDAN STREET <br />
                SUITE 200 HOLLYWOOD <br />
                FL 33021 <br />
              </address>
            </div>
          </div>

          <ul className="flex items-center justify-between mt-8">
            <li>
              <a href="#">
                <Facebook />
              </a>
            </li>
            <li className="ml-3">
              <a href="#">
                <Instagram />
              </a>
            </li>
            <li className="ml-3">
              <a href="#">
                <X />
              </a>
            </li>
            <li className="ml-3">
              <a href="#">
                <LinkedIn />
              </a>
            </li>
          </ul>
        </section>
      </div>
      <div className="mt-8 flex justify-between items-center">
        <p className="text-background text-sm">
          © {new Date()?.getFullYear()} DriveLife.ai All rights reserved.
        </p>
        <ul className="flex items-center">
          <li>
            <a
              className="text-sm font-normal text-background"
              href="/privacy-policy"
            >
              Privacy Policy
            </a>
          </li>
          <li className="ml-6">
            <a
              className="text-sm font-normal text-background"
              href="/terms-of-service"
            >
              Terms of Service
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
