import { postGetStartedForm } from 'apis/getStarted';
import { GetStartedFormData } from 'components/GetStartedModal/schema';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

export const useSubmitGetStartedForm = ({
  closeModal,
}: {
  closeModal: () => void;
}) => {
  const { mutate, error, isLoading, isSuccess } = useMutation({
    mutationFn: ({ formData }: { formData: GetStartedFormData }) =>
      postGetStartedForm(formData),
    onSuccess: async () => {
      toast.success('Form submitted successfully');
    },
    onError: () => {
      toast.error('Something went wrong, please try again later');
    },
    onSettled: () => {
      closeModal();
    },
  });

  return { postGetStartedForm: mutate, isLoading, error, isSuccess };
};
