import React, { useState } from 'react';
import { BenefitType } from 'utils/types';
import PageDescription from 'components/core/PageDescription/PageDescription';
import Benefit from './components/Benefit';
import PageTitle from 'components/core/PageTitle/PageTitle';
import { benefitImages } from 'utils/constants';
import Label from 'components/core/Label/Label';

const OurBenefits = () => {
  const [selectedBenefit, setSelectedBenefit] = useState<BenefitType>(
    BenefitType.RealTimeInventoryTracking,
  );
  const handleSelectBenefit = (benefit: BenefitType) => {
    setSelectedBenefit(benefit);
  };

  return (
    <div className="flex items-center justify-center" id="our-benefits">
      <div className="py-[7.5rem] px-[5.25rem]">
        <div className="flex">
          <img
            src={benefitImages[selectedBenefit].src}
            width={596}
            alt={benefitImages[selectedBenefit].alt}
          />
          <section className="ml-[6.25rem] mt-px">
            <Label>Our Benefits</Label>
            <PageTitle
              title="Unlock the Full Potential of"
              outlinedPart="Your Dealership"
              className="max-w-[32rem] mt-6"
            />
            <PageDescription
              text="Discover the advantages of our lot management system tailored to
              enhance your operations."
              className="mt-6 max-w-[36rem]"
            />

            <div className="mt-[3.25rem]">
              <Benefit
                title="Real-Time Inventory Tracking"
                description="Monitor and manage your entire inventory in real-time,
                  ensuring you always know the status and location of each
                  vehicle on your lot."
                isActive={
                  selectedBenefit === BenefitType.RealTimeInventoryTracking
                }
                onSelectBenefit={() =>
                  handleSelectBenefit(BenefitType.RealTimeInventoryTracking)
                }
              />

              <Benefit
                title="Improved Sales Efficiency"
                description="Enhance your sales process with streamlined workflows and
                  instant access to critical data, enabling your team to close
                  deals faster and more effectively."
                isActive={
                  selectedBenefit === BenefitType.ImprovedSalesEfficiency
                }
                onSelectBenefit={() =>
                  handleSelectBenefit(BenefitType.ImprovedSalesEfficiency)
                }
                className="mt-6"
              />

              <Benefit
                title="Enhanced Customer Experience"
                description="Provide a superior customer experience with quick access to
                  vehicle information, reducing wait times and improving overall
                  satisfaction."
                isActive={
                  selectedBenefit === BenefitType.EnhancedCustomerExperience
                }
                onSelectBenefit={() =>
                  handleSelectBenefit(BenefitType.EnhancedCustomerExperience)
                }
                className="mt-6"
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default OurBenefits;
