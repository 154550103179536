import axios from 'axios';
import { GetStartedFormData } from 'components/GetStartedModal/schema';

export const postGetStartedForm = async (data: GetStartedFormData) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/get-started`,
    data,
  );
  return response.data;
};
