import React from 'react';

const DriveLifeLogo = () => {
  return (
    <svg
      width="106"
      height="32"
      viewBox="0 0 106 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37 10.2094H40.898C41.8107 10.2094 42.6322 10.349 43.3624 10.6282C44.0926 10.9074 44.7154 11.3047 45.2309 11.8201C45.7463 12.3248 46.1383 12.9315 46.4067 13.6403C46.6859 14.349 46.8255 15.1275 46.8255 15.9758C46.8255 16.8349 46.6859 17.6188 46.4067 18.3275C46.1383 19.0362 45.7463 19.643 45.2309 20.1477C44.7154 20.6523 44.0926 21.0443 43.3624 21.3235C42.6322 21.6027 41.8107 21.7423 40.898 21.7423H37V10.2094ZM40.8013 19.6805C41.4349 19.6805 41.9933 19.5946 42.4765 19.4228C42.9597 19.2403 43.3624 18.9879 43.6846 18.6658C44.0067 18.3436 44.2483 17.957 44.4094 17.506C44.5705 17.0443 44.651 16.5342 44.651 15.9758C44.651 15.4174 44.5705 14.9128 44.4094 14.4617C44.2483 14 44.0067 13.6081 43.6846 13.2859C43.3624 12.9638 42.9597 12.7168 42.4765 12.545C41.9933 12.3624 41.4349 12.2711 40.8013 12.2711H39.1745V19.6805H40.8013Z"
        fill="#192252"
      />
      <path
        d="M48.0049 13.8497H49.9861V14.945H50.1149C50.2116 14.7517 50.3404 14.5745 50.5015 14.4134C50.6626 14.2523 50.8398 14.1128 51.0331 13.9946C51.2371 13.8765 51.4518 13.7852 51.6773 13.7208C51.9136 13.6564 52.1445 13.6242 52.37 13.6242C52.6492 13.6242 52.8854 13.651 53.0787 13.7047C53.2827 13.7584 53.4545 13.8282 53.5941 13.9141L53.0304 15.8309C52.9015 15.7664 52.7565 15.7181 52.5955 15.6859C52.4451 15.643 52.2572 15.6215 52.0317 15.6215C51.7418 15.6215 51.4787 15.6805 51.2424 15.7987C51.0062 15.906 50.8022 16.0617 50.6304 16.2658C50.4693 16.4698 50.3404 16.7114 50.2438 16.9906C50.1579 17.2591 50.1149 17.5544 50.1149 17.8765V21.7423H48.0049V13.8497Z"
        fill="#192252"
      />
      <path
        d="M55.7401 12.706C55.5575 12.706 55.3803 12.6738 55.2085 12.6094C55.0474 12.5342 54.9025 12.4376 54.7736 12.3195C54.6555 12.1906 54.5588 12.0456 54.4837 11.8846C54.4193 11.7235 54.387 11.5463 54.387 11.353C54.387 11.1597 54.4193 10.9826 54.4837 10.8215C54.5588 10.6604 54.6555 10.5208 54.7736 10.4027C54.9025 10.2738 55.0474 10.1772 55.2085 10.1128C55.3803 10.0376 55.5575 10 55.7401 10C56.1159 10 56.438 10.1342 56.7065 10.4027C56.975 10.6604 57.1092 10.9772 57.1092 11.353C57.1092 11.7289 56.975 12.051 56.7065 12.3195C56.438 12.5772 56.1159 12.706 55.7401 12.706ZM54.6931 21.7423V13.8497H56.8031V21.7423H54.6931Z"
        fill="#192252"
      />
      <path
        d="M57.895 13.8497H60.295L62.2601 18.8752H62.3729L64.3541 13.8497H66.738L63.3393 21.7423H61.2615L57.895 13.8497Z"
        fill="#192252"
      />
      <path
        d="M74.9365 19.8416C74.5714 20.4859 74.0775 21.0067 73.4547 21.404C72.8426 21.8013 72.0909 22 71.1996 22C70.5983 22 70.0399 21.898 69.5244 21.694C69.0198 21.4792 68.5795 21.1839 68.2036 20.8081C67.8278 20.4322 67.5325 19.9919 67.3177 19.4872C67.1137 18.9718 67.0117 18.4081 67.0117 17.796C67.0117 17.2268 67.1137 16.6899 67.3177 16.1852C67.5218 15.6698 67.8063 15.2242 68.1714 14.8483C68.5365 14.4617 68.9661 14.1557 69.46 13.9302C69.9647 13.7047 70.5177 13.5919 71.1191 13.5919C71.7526 13.5919 72.3164 13.6993 72.8104 13.9141C73.3043 14.1181 73.7177 14.4081 74.0506 14.7839C74.3835 15.149 74.6359 15.5839 74.8077 16.0886C74.9795 16.5933 75.0654 17.1409 75.0654 17.7315C75.0654 17.8067 75.0654 17.8711 75.0654 17.9248C75.0546 17.9893 75.0493 18.0483 75.0493 18.102C75.0385 18.1557 75.0332 18.2148 75.0332 18.2792H69.0896C69.1325 18.6013 69.2184 18.8805 69.3473 19.1168C69.4869 19.3423 69.6533 19.5356 69.8466 19.6966C70.0506 19.847 70.2708 19.9597 70.507 20.0349C70.7432 20.0993 70.9849 20.1315 71.2318 20.1315C71.7151 20.1315 72.1124 20.0242 72.4238 19.8094C72.7459 19.5839 72.9983 19.3047 73.1808 18.9718L74.9365 19.8416ZM73.0198 16.7812C73.009 16.6416 72.9607 16.4859 72.8748 16.3141C72.7996 16.1423 72.6815 15.9812 72.5204 15.8309C72.3701 15.6805 72.1768 15.557 71.9406 15.4604C71.7151 15.3638 71.4412 15.3154 71.1191 15.3154C70.6681 15.3154 70.2708 15.4443 69.9271 15.702C69.5835 15.9597 69.3419 16.3195 69.2023 16.7812H73.0198Z"
        fill="#192252"
      />
      <path
        d="M76.6707 10.2094H78.8452V19.6805H83.7257V21.7423H76.6707V10.2094Z"
        fill="#192252"
      />
      <path
        d="M86.1931 12.706C86.0105 12.706 85.8333 12.6738 85.6615 12.6094C85.5005 12.5342 85.3555 12.4376 85.2266 12.3195C85.1085 12.1906 85.0119 12.0456 84.9367 11.8846C84.8723 11.7235 84.84 11.5463 84.84 11.353C84.84 11.1597 84.8723 10.9826 84.9367 10.8215C85.0119 10.6604 85.1085 10.5208 85.2266 10.4027C85.3555 10.2738 85.5005 10.1772 85.6615 10.1128C85.8333 10.0376 86.0105 10 86.1931 10C86.5689 10 86.8911 10.1342 87.1595 10.4027C87.428 10.6604 87.5622 10.9772 87.5622 11.353C87.5622 11.7289 87.428 12.051 87.1595 12.3195C86.8911 12.5772 86.5689 12.706 86.1931 12.706ZM85.1461 21.7423V13.8497H87.2562V21.7423H85.1461Z"
        fill="#192252"
      />
      <path
        d="M93.0836 10.0805C93.3843 10.0805 93.6635 10.1074 93.9212 10.1611C94.1789 10.2148 94.4098 10.2846 94.6138 10.3705L94.0179 12.255C93.9105 12.2013 93.7924 12.1584 93.6635 12.1262C93.5454 12.094 93.395 12.0779 93.2125 12.0779C92.9011 12.0779 92.6541 12.1799 92.4715 12.3839C92.289 12.5772 92.1977 12.851 92.1977 13.2054V13.8497H94.1628V15.6537H92.1977V21.7423H90.0877V15.6537H88.6702V13.8497H90.0877V13.0765C90.0877 12.5933 90.1575 12.1691 90.2971 11.804C90.4474 11.4282 90.6568 11.1168 90.9252 10.8698C91.1937 10.6121 91.5105 10.4188 91.8756 10.2899C92.2407 10.1503 92.6434 10.0805 93.0836 10.0805Z"
        fill="#192252"
      />
      <path
        d="M102.574 19.8416C102.209 20.4859 101.715 21.0067 101.092 21.404C100.48 21.8013 99.7283 22 98.837 22C98.2356 22 97.6773 21.898 97.1618 21.694C96.6571 21.4792 96.2169 21.1839 95.841 20.8081C95.4652 20.4322 95.1699 19.9919 94.9551 19.4872C94.7511 18.9718 94.6491 18.4081 94.6491 17.796C94.6491 17.2268 94.7511 16.6899 94.9551 16.1852C95.1591 15.6698 95.4437 15.2242 95.8088 14.8483C96.1739 14.4617 96.6034 14.1557 97.0974 13.9302C97.6021 13.7047 98.1551 13.5919 98.7565 13.5919C99.39 13.5919 99.9538 13.6993 100.448 13.9141C100.942 14.1181 101.355 14.4081 101.688 14.7839C102.021 15.149 102.273 15.5839 102.445 16.0886C102.617 16.5933 102.703 17.1409 102.703 17.7315C102.703 17.8067 102.703 17.8711 102.703 17.9248C102.692 17.9893 102.687 18.0483 102.687 18.102C102.676 18.1557 102.671 18.2148 102.671 18.2792H96.7269C96.7699 18.6013 96.8558 18.8805 96.9846 19.1168C97.1242 19.3423 97.2907 19.5356 97.484 19.6966C97.688 19.847 97.9081 19.9597 98.1444 20.0349C98.3806 20.0993 98.6222 20.1315 98.8692 20.1315C99.3524 20.1315 99.7497 20.0242 100.061 19.8094C100.383 19.5839 100.636 19.3047 100.818 18.9718L102.574 19.8416ZM100.657 16.7812C100.646 16.6416 100.598 16.4859 100.512 16.3141C100.437 16.1423 100.319 15.9812 100.158 15.8309C100.007 15.6805 99.8142 15.557 99.5779 15.4604C99.3524 15.3638 99.0786 15.3154 98.7565 15.3154C98.3054 15.3154 97.9081 15.4443 97.5645 15.702C97.2209 15.9597 96.9793 16.3195 96.8397 16.7812H100.657Z"
        fill="#192252"
      />
      <path
        d="M0 7.43952L0 24.5605C0 28.6692 3.33078 32 7.43952 32H24.5605C28.6692 32 32 28.6692 32 24.5605V7.43952C32 3.33078 28.6692 0 24.5605 0H7.43952C3.33078 0 0 3.33078 0 7.43952Z"
        fill="#3D5BF6"
      />
      <path
        d="M22.7936 13.2737C22.4404 12.373 21.8998 11.5483 21.1846 10.8212C20.4693 10.0951 19.5736 9.50815 18.5229 9.07803C17.4782 8.65087 16.248 8.43384 14.8659 8.43384H10.7561C10.1869 8.43384 9.67193 8.63114 9.26647 9.00306C8.83833 9.39766 8.60156 9.92939 8.60156 10.5006V21.4894C8.60156 22.0704 8.84425 22.6041 9.28226 22.9918C9.68476 23.349 10.1948 23.5384 10.7551 23.5384H14.8649C16.248 23.5384 17.4782 23.3213 18.522 22.8942C19.5726 22.4641 20.4684 21.8781 21.1836 21.151C21.8978 20.4249 22.4394 19.6002 22.7926 18.6985C23.1408 17.8097 23.3174 16.8972 23.3174 15.9856C23.3174 15.0741 23.1408 14.1615 22.7926 13.2717L22.7936 13.2737ZM18.518 17.8136C18.2152 18.2891 17.8018 18.665 17.2572 18.958C17.2 18.9895 17.1408 19.0191 17.0807 19.0487C16.4148 19.3694 15.594 19.5321 14.642 19.5321H12.9314V12.442H14.642C15.594 12.442 16.4148 12.6048 17.0807 12.9254C17.714 13.2313 18.1836 13.6347 18.518 14.1596C18.8495 14.6805 19.0103 15.2783 19.0103 15.9866C19.0103 16.6949 18.8495 17.2927 18.518 17.8136Z"
        fill="white"
      />
      <path
        d="M12.9304 12.4417V16.7705H8.60156C8.60156 14.3811 10.5411 12.4417 12.9304 12.4417Z"
        fill="url(#paint0_linear_6933_7405)"
      />
      <path
        d="M17.2601 18.9575V23.2863C16.0644 23.2863 14.9822 22.802 14.1989 22.0187C13.5439 21.3626 13.097 20.4975 12.9688 19.5317H14.6419C15.5949 19.5317 16.4146 19.3689 17.0805 19.0483C17.1407 19.0187 17.1999 18.9891 17.2571 18.9575H17.2601Z"
        fill="url(#paint1_linear_6933_7405)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6933_7405"
          x1="12.9809"
          y1="16.7067"
          x2="12.9809"
          y2="12.4889"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#3D5BF6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6933_7405"
          x1="17.1992"
          y1="20.1945"
          x2="12.9814"
          y2="20.1945"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#3D5BF6" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DriveLifeLogo;
