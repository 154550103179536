import React from 'react';
import Star from 'assets/icons/Star';
import Award from 'assets/icons/Award';
import Label from 'components/core/Label/Label';

const AboutUs = () => {
  return (
    <div className="flex items-center justify-center" id="about-us">
      <div className="py-[7.5rem] px-[5.25rem]">
        <Label>About Us</Label>
        <div className="flex mt-6">
          <section>
            <h3 className="text-[2.625rem] text-typography font-bold max-w-[58rem] capitalize">
              Welcome to DriveLife.Ai,
            </h3>
            <h3 className="text-[2.625rem] text-typography-60 font-bold max-w-[58rem] capitalize">
              Your trusted partner in <br />
              dealership lot management
            </h3>
            <p className="text-typography-80 text-base font-normal mt-6 max-w-[36rem] font-noto">
              Founded with a passion for excellence and a commitment to customer
              satisfaction, we have been serving the automotive industry with
              unparalleled dedication and expertise. Our mission is to
              streamline and optimize the operations of dealership lots,
              ensuring that every vehicle is presented in its best light and
              that the lot runs smoothly and efficiently. We understand the
              unique challenges that dealerships face, and we are here to
              provide tailored solutions that meet your specific needs.
            </p>
            <h6 className="text-[2rem] text-typography font-bold max-w-[58rem] mt-[3.25rem] capitalize">
              Why Choose Us?
            </h6>
            <div className="mt-5 max-w-[31rem]">
              <div className="flex pb-6 border-b border-background-60">
                <div>
                  <Label className="px-[10px] py-[10px]">
                    <Star />
                  </Label>
                </div>

                <div className="ml-6">
                  <p className="text-2xl text-typography font-bold">Quality</p>
                  <p className="text-typography-80 text-base font-normal mt-3 max-w-[21rem]">
                    We pride ourselves on delivering high-quality services that
                    exceed our clients&apos; expectations.
                  </p>
                </div>
              </div>
              <div className="flex mt-6">
                <div>
                  <Label className="px-[10px] py-[10px]">
                    <Award />
                  </Label>
                </div>

                <div className="ml-6">
                  <p className="text-2xl text-typography font-bold">
                    Expertise
                  </p>
                  <p className="text-typography-80 text-base font-normal mt-3 max-w-[22rem]">
                    With years of experience in the industry, our team of
                    professionals brings a wealth of knowledge and skills to
                    every project.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div className="ml-[6.25rem]">
            <img
              src="/images/about-us/my-trips.png"
              width={596}
              alt="About Us"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
