import React from 'react';
import classNames from 'classnames';

export type PageTitleProps = {
  text: string;
  className?: string;
};
const PageDescription: React.FC<PageTitleProps> = ({
  text,
  className,
}: PageTitleProps) => {
  return (
    <p
      className={classNames(
        'text-typography-80 text-base font-normal font-noto',
        className,
      )}
    >
      {text}
    </p>
  );
};

export default PageDescription;
