import React from 'react';
import classNames from 'classnames';

export type LabelProps = {
  children: React.ReactNode;
  className?: string;
};
const Label: React.FC<LabelProps> = ({ children, className }: LabelProps) => {
  return (
    <button
      className={classNames(
        'px-[1.125rem] py-3 text-xs bg-background text-primary-80 uppercase border border-grey-10 rounded-[100px] shadow-sm font-bold',
        className,
      )}
    >
      {children}
    </button>
  );
};

export default Label;
