import * as yup from 'yup';

export const getStartedFormSchema = yup
  .object({
    organizationName: yup.string().required('Organization name is required'),
    contactPerson: yup.string().required('Contact person is required'),
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('Email is required'),
    text: yup.string().required('Text is required'),
  })
  .required();

export type GetStartedFormData = yup.InferType<typeof getStartedFormSchema>;
