import React from 'react';
import classNames from 'classnames';
import { UseFormRegister } from 'react-hook-form';
import { GetStartedFormData } from 'components/GetStartedModal/schema';

export type InputProps = {
  name: keyof GetStartedFormData;
  label: string;
  wrapperClassName?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  error?: string;
  register: UseFormRegister<GetStartedFormData>;
};
const Input: React.FC<InputProps> = ({
  name,
  label,
  inputProps,
  wrapperClassName,
  error,
  register,
}: InputProps) => {
  const { className: inputClassName, ...otherInputProps } = inputProps || {};

  return (
    <div className={wrapperClassName}>
      {label && (
        <label htmlFor={name} className="text-sm font-medium text-typography">
          {label}
        </label>
      )}

      <input
        id={name}
        className={classNames([
          'mt-1.5 h-14 border border-background-20 rounded-lg w-full py-4 pl-6 placeholder:text-base placeholder:font-normal text-typography font-normal',
          inputClassName,
        ])}
        {...otherInputProps}
        {...register(name)}
      />
      <p className="text-xs font-normal text-error mt-2">{error}</p>
    </div>
  );
};

export default Input;
