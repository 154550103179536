import React from 'react';
import Button from 'components/core/Button/Button';
import PageTitle from 'components/core/PageTitle/PageTitle';
import PageDescription from 'components/core/PageDescription/PageDescription';
import Label from 'components/core/Label/Label';
import { useGetStartedModalContext } from 'components/GetStartedModal/GetStartedModalContext';

const GetStarted = () => {
  const { setIsGetStartedModalOpen } = useGetStartedModalContext();

  return (
    <div className="pb-16 px-16" id="get-started">
      <div className="bg-background-10 border border-grey-10 rounded-3xl shadow-xs">
        <Label className="mt-[6.25rem] mx-auto flex">Get Started</Label>
        <PageTitle
          title="Take Control of Your Dealership"
          outlinedPart="Today"
          className="text-center mt-4"
        />
        <PageDescription
          text="Maximize your dealership's efficiency and productivity with our comprehensive lot management system. Experience the benefits of streamlined operations, real-time inventory tracking, and enhanced customer satisfaction. Click the button below to get started and transform your dealership management."
          className="mt-6 max-w-[32rem]  text-center mx-auto"
        />
        <div className="mt-4 bg-get-started bg-cover min-h-[732px]">
          <Button
            type="primary"
            className="mx-auto"
            size="lg"
            onClick={() => setIsGetStartedModalOpen(true)}
          >
            Get Started
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
