import React from 'react';

interface TermsOfServiceSectionProps {
  index: number;
  title: string;
  description: string[];
}

const TermsOfServiceSection = ({
  title,
  index,
  description,
}: TermsOfServiceSectionProps) => {
  return (
    <>
      <h4 className="text-2xl text-typography font-bold mt-16">
        {index}. {title}
      </h4>
      {description.length === 1 && (
        <p className="text-typography-80 text-lg font-normal mt-6">
          {description}
        </p>
      )}
      {description?.length > 1 && (
        <ul className="list-disc pl-4">
          {description.map((paragraph, paragraphIndex) => (
            <li key={paragraphIndex}>
              <p className="text-typography-80 text-lg font-normal mt-6">
                {paragraph}
              </p>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default TermsOfServiceSection;
