import React from 'react';
import classNames from 'classnames';

export type PageTitleProps = {
  title: string;
  outlinedPart: string;
  className?: string;
};
const PageTitle: React.FC<PageTitleProps> = ({
  title,
  outlinedPart,
  className,
}: PageTitleProps) => {
  return (
    <h3
      className={classNames(
        'text-[2.625rem] text-typography font-bold capitalize',
        className,
      )}
    >
      {title} <span className="text-typography-60">{outlinedPart}</span>
    </h3>
  );
};

export default PageTitle;
