import React from 'react';

const Award = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="award-fill">
        <path
          id="Vector"
          d="M12 1.99878C10.936 1.99878 9.94801 2.37078 9.094 2.99878C8.062 3.16278 7.09 3.59577 6.344 4.34277C5.597 5.08977 5.164 6.06077 5 7.09277C4.372 7.94677 4 8.93478 4 9.99878C4 11.0628 4.372 12.0508 5 12.9048C5.164 13.9368 5.597 14.9078 6.344 15.6548C6.534 15.8448 6.78401 15.9428 7.00001 16.0928V20.9988C7.00001 21.5508 7.44801 21.9988 8.00001 21.9988H9.00001C9.10701 21.9988 9.21102 21.9708 9.31202 21.9368L12 21.0298L14.688 21.9368C14.789 21.9698 14.893 21.9988 15 21.9988H16C16.552 21.9988 17 21.5508 17 20.9988V16.0928C17.216 15.9428 17.466 15.8448 17.656 15.6548C18.403 14.9078 18.836 13.9368 19 12.9048C19.628 12.0508 20 11.0628 20 9.99878C20 8.93478 19.628 7.94677 19 7.09277C18.836 6.06077 18.403 5.08977 17.656 4.34277C16.909 3.59577 15.938 3.16278 14.906 2.99878C14.052 2.37078 13.064 1.99878 12 1.99878ZM12 3.99878C15.314 3.99878 18 6.68478 18 9.99878C18 13.3128 15.314 15.9988 12 15.9988C8.68601 15.9988 6 13.3128 6 9.99878C6 6.68478 8.68601 3.99878 12 3.99878ZM12 5.99878C9.79101 5.99878 8.00001 7.78978 8.00001 9.99878C8.00001 12.2078 9.79101 13.9988 12 13.9988C14.209 13.9988 16 12.2078 16 9.99878C16 7.78978 14.209 5.99878 12 5.99878Z"
          fill="#3D5BF6"
        />
      </g>
    </svg>
  );
};

export default Award;
