import React from 'react';

const DriveLifeLogoWhite = ({ width = '128' }: { width?: string }) => {
  return (
    <svg
      width={width}
      height="42"
      viewBox="0 0 140 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.5625 14.6894H53.2523C54.3504 14.6894 55.3388 14.8574 56.2173 15.1933C57.0958 15.5292 57.8451 16.0072 58.4653 16.6273C59.0854 17.2346 59.557 17.9645 59.8799 18.8172C60.2159 19.6699 60.3838 20.6065 60.3838 21.6272C60.3838 22.6607 60.2159 23.6039 59.8799 24.4565C59.557 25.3092 59.0854 26.0392 58.4653 26.6464C57.8451 27.2536 57.0958 27.7252 56.2173 28.0611C55.3388 28.397 54.3504 28.5649 53.2523 28.5649H48.5625V14.6894ZM53.136 26.0844C53.8982 26.0844 54.5701 25.981 55.1514 25.7743C55.7328 25.5547 56.2173 25.2511 56.6049 24.8635C56.9924 24.4759 57.2831 24.0108 57.4769 23.4682C57.6707 22.9127 57.7676 22.299 57.7676 21.6272C57.7676 20.9554 57.6707 20.3482 57.4769 19.8055C57.2831 19.25 56.9924 18.7784 56.6049 18.3909C56.2173 18.0033 55.7328 17.7061 55.1514 17.4994C54.5701 17.2798 53.8982 17.17 53.136 17.17H51.1787V26.0844H53.136Z"
        fill="white"
      />
      <path
        d="M61.8027 19.0691H64.1864V20.3869H64.3414C64.4577 20.1544 64.6127 19.9412 64.8065 19.7474C65.0003 19.5536 65.2135 19.3857 65.446 19.2435C65.6915 19.1014 65.9499 18.9916 66.2212 18.9141C66.5054 18.8366 66.7832 18.7978 67.0545 18.7978C67.3904 18.7978 67.6746 18.8301 67.9072 18.8947C68.1526 18.9593 68.3594 19.0433 68.5273 19.1466L67.849 21.4528C67.694 21.3753 67.5196 21.3171 67.3258 21.2784C67.1449 21.2267 66.9188 21.2008 66.6475 21.2008C66.2987 21.2008 65.9822 21.2719 65.6979 21.414C65.4137 21.5432 65.1682 21.7305 64.9615 21.976C64.7677 22.2215 64.6127 22.5122 64.4964 22.8481C64.3931 23.1711 64.3414 23.5263 64.3414 23.9139V28.5649H61.8027V19.0691Z"
        fill="white"
      />
      <path
        d="M71.1091 17.6932C70.8895 17.6932 70.6763 17.6544 70.4696 17.5769C70.2758 17.4865 70.1014 17.3702 69.9464 17.2281C69.8043 17.0731 69.688 16.8987 69.5976 16.7049C69.52 16.5111 69.4813 16.2979 69.4813 16.0654C69.4813 15.8328 69.52 15.6196 69.5976 15.4258C69.688 15.232 69.8043 15.0641 69.9464 14.922C70.1014 14.7669 70.2758 14.6507 70.4696 14.5732C70.6763 14.4827 70.8895 14.4375 71.1091 14.4375C71.5613 14.4375 71.9489 14.599 72.2719 14.922C72.5949 15.232 72.7564 15.6132 72.7564 16.0654C72.7564 16.5175 72.5949 16.9051 72.2719 17.2281C71.9489 17.5382 71.5613 17.6932 71.1091 17.6932ZM69.8495 28.5649V19.0691H72.3882V28.5649H69.8495Z"
        fill="white"
      />
      <path
        d="M73.7019 19.0691H76.5894L78.9536 25.1154H79.0893L81.4729 19.0691H84.341L80.252 28.5649H77.7521L73.7019 19.0691Z"
        fill="white"
      />
      <path
        d="M94.2049 26.2782C93.7656 27.0534 93.1713 27.6799 92.422 28.158C91.6856 28.636 90.7812 28.875 89.7089 28.875C88.9854 28.875 88.3136 28.7523 87.6935 28.5068C87.0863 28.2484 86.5566 27.8931 86.1044 27.4409C85.6522 26.9888 85.2969 26.4591 85.0385 25.8518C84.7931 25.2317 84.6703 24.5534 84.6703 23.817C84.6703 23.1323 84.7931 22.4863 85.0385 21.8791C85.284 21.259 85.6264 20.7228 86.0656 20.2706C86.5049 19.8055 87.0217 19.4373 87.616 19.166C88.2232 18.8947 88.8885 18.7591 89.612 18.7591C90.3743 18.7591 91.0525 18.8883 91.6468 19.1466C92.2411 19.3921 92.7385 19.7409 93.139 20.1931C93.5395 20.6324 93.8431 21.1556 94.0499 21.7628C94.2566 22.37 94.3599 23.0289 94.3599 23.7395C94.3599 23.8299 94.3599 23.9075 94.3599 23.9721C94.347 24.0496 94.3405 24.1206 94.3405 24.1852C94.3276 24.2498 94.3212 24.3209 94.3212 24.3984H87.1702C87.2219 24.786 87.3253 25.1219 87.4803 25.4061C87.6483 25.6774 87.8485 25.91 88.0811 26.1038C88.3265 26.2846 88.5914 26.4203 88.8756 26.5107C89.1598 26.5883 89.4505 26.627 89.7477 26.627C90.3291 26.627 90.8071 26.4978 91.1817 26.2394C91.5693 25.9681 91.8729 25.6322 92.0926 25.2317L94.2049 26.2782ZM91.8988 22.5961C91.8858 22.4282 91.8277 22.2409 91.7244 22.0341C91.6339 21.8274 91.4918 21.6336 91.298 21.4528C91.1171 21.2719 90.8846 21.1233 90.6004 21.007C90.3291 20.8908 89.9996 20.8326 89.612 20.8326C89.0694 20.8326 88.5914 20.9877 88.178 21.2977C87.7645 21.6078 87.4739 22.0406 87.3059 22.5961H91.8988Z"
        fill="white"
      />
      <path
        d="M96.2913 14.6894H98.9075V26.0844H104.779V28.5649H96.2913V14.6894Z"
        fill="white"
      />
      <path
        d="M107.748 17.6932C107.528 17.6932 107.315 17.6544 107.108 17.5769C106.915 17.4865 106.74 17.3702 106.585 17.2281C106.443 17.0731 106.327 16.8987 106.236 16.7049C106.159 16.5111 106.12 16.2979 106.12 16.0654C106.12 15.8328 106.159 15.6196 106.236 15.4258C106.327 15.232 106.443 15.0641 106.585 14.922C106.74 14.7669 106.915 14.6507 107.108 14.5732C107.315 14.4827 107.528 14.4375 107.748 14.4375C108.2 14.4375 108.588 14.599 108.911 14.922C109.234 15.232 109.395 15.6132 109.395 16.0654C109.395 16.5175 109.234 16.9051 108.911 17.2281C108.588 17.5382 108.2 17.6932 107.748 17.6932ZM106.488 28.5649V19.0691H109.027V28.5649H106.488Z"
        fill="white"
      />
      <path
        d="M116.038 14.5344C116.4 14.5344 116.736 14.5667 117.046 14.6313C117.356 14.6959 117.634 14.7799 117.879 14.8832L117.162 17.1506C117.033 17.086 116.891 17.0343 116.736 16.9956C116.594 16.9568 116.413 16.9374 116.193 16.9374C115.818 16.9374 115.521 17.0602 115.302 17.3056C115.082 17.5382 114.972 17.8676 114.972 18.294V19.0691H117.337V21.2396H114.972V28.5649H112.434V21.2396H110.728V19.0691H112.434V18.1389C112.434 17.5576 112.518 17.0472 112.686 16.608C112.866 16.1558 113.118 15.7811 113.441 15.484C113.764 15.1739 114.145 14.9414 114.585 14.7863C115.024 14.6184 115.508 14.5344 116.038 14.5344Z"
        fill="white"
      />
      <path
        d="M127.456 26.2782C127.017 27.0534 126.423 27.6799 125.673 28.158C124.937 28.636 124.032 28.875 122.96 28.875C122.237 28.875 121.565 28.7523 120.945 28.5068C120.337 28.2484 119.808 27.8931 119.356 27.4409C118.903 26.9888 118.548 26.4591 118.29 25.8518C118.044 25.2317 117.922 24.5534 117.922 23.817C117.922 23.1323 118.044 22.4863 118.29 21.8791C118.535 21.259 118.878 20.7228 119.317 20.2706C119.756 19.8055 120.273 19.4373 120.867 19.166C121.474 18.8947 122.14 18.7591 122.863 18.7591C123.625 18.7591 124.304 18.8883 124.898 19.1466C125.492 19.3921 125.99 19.7409 126.39 20.1931C126.791 20.6324 127.094 21.1556 127.301 21.7628C127.508 22.37 127.611 23.0289 127.611 23.7395C127.611 23.8299 127.611 23.9075 127.611 23.9721C127.598 24.0496 127.592 24.1206 127.592 24.1852C127.579 24.2498 127.572 24.3209 127.572 24.3984H120.421C120.473 24.786 120.576 25.1219 120.732 25.4061C120.899 25.6774 121.1 25.91 121.332 26.1038C121.578 26.2846 121.843 26.4203 122.127 26.5107C122.411 26.5883 122.702 26.627 122.999 26.627C123.58 26.627 124.058 26.4978 124.433 26.2394C124.821 25.9681 125.124 25.6322 125.344 25.2317L127.456 26.2782ZM125.15 22.5961C125.137 22.4282 125.079 22.2409 124.976 22.0341C124.885 21.8274 124.743 21.6336 124.549 21.4528C124.368 21.2719 124.136 21.1233 123.852 21.007C123.58 20.8908 123.251 20.8326 122.863 20.8326C122.321 20.8326 121.843 20.9877 121.429 21.2977C121.016 21.6078 120.725 22.0406 120.557 22.5961H125.15Z"
        fill="white"
      />
      <path
        d="M0 9.76437L0 32.2356C0 37.6283 4.37157 42 9.76417 42H32.235C37.6276 42 41.9992 37.6283 41.9992 32.2356V9.76437C41.9992 4.37165 37.6276 0 32.235 0H9.76417C4.37157 0 0 4.37165 0 9.76437Z"
        fill="white"
      />
      <path
        d="M29.9157 17.4246C29.4522 16.2424 28.7426 15.16 27.8039 14.2057C26.8652 13.2527 25.6895 12.4823 24.3106 11.9178C22.9394 11.3571 21.3249 11.0723 19.5109 11.0723H14.1169C13.3698 11.0723 12.6939 11.3312 12.1617 11.8194C11.5998 12.3373 11.2891 13.0352 11.2891 13.7849V28.2077C11.2891 28.9703 11.6076 29.6708 12.1825 30.1796C12.7107 30.6484 13.3801 30.897 14.1156 30.897H19.5096C21.3249 30.897 22.9394 30.6121 24.3093 30.0515C25.6883 29.4869 26.8639 28.7178 27.8026 27.7635C28.74 26.8106 29.4509 25.7281 29.9144 24.5447C30.3715 23.3781 30.6032 22.1804 30.6032 20.984C30.6032 19.7876 30.3715 18.5899 29.9144 17.422L29.9157 17.4246ZM24.3041 23.3832C23.9066 24.0073 23.3641 24.5006 22.6494 24.8852C22.5743 24.9266 22.4966 24.9655 22.4177 25.0043C21.5437 25.4251 20.4664 25.6388 19.217 25.6388H16.9718V16.333H19.217C20.4664 16.333 21.5437 16.5467 22.4177 16.9675C23.2489 17.3689 23.8652 17.8985 24.3041 18.5873C24.7392 19.2709 24.9502 20.0556 24.9502 20.9853C24.9502 21.9149 24.7392 22.6996 24.3041 23.3832Z"
        fill="#3D5BF6"
      />
      <path
        d="M16.9705 16.334V22.0156H11.2891C11.2891 18.8796 13.8346 16.334 16.9705 16.334Z"
        fill="url(#paint0_linear_6933_6575)"
      />
      <path
        d="M22.6557 24.8862V30.5678C21.0864 30.5678 19.6661 29.9321 18.638 28.904C17.7783 28.043 17.1918 26.9074 17.0234 25.6398H19.2194C20.4701 25.6398 21.5461 25.4262 22.4201 25.0054C22.499 24.9665 22.5767 24.9277 22.6518 24.8862H22.6557Z"
        fill="url(#paint1_linear_6933_6575)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6933_6575"
          x1="17.0369"
          y1="16.3961"
          x2="17.0369"
          y2="21.9319"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#001DB5" />
          <stop offset="1" stopColor="#3D5BF6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6933_6575"
          x1="22.5758"
          y1="26.5097"
          x2="17.0401"
          y2="26.5097"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3D5BF6" />
          <stop offset="1" stopColor="#001DB5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DriveLifeLogoWhite;
