import { BenefitType } from './types';

export const benefitImages: {
  [key in BenefitType]: { src: string; alt: string };
} = {
  [BenefitType.RealTimeInventoryTracking]: {
    src: '/images/our-benefits/real-time-inventory-tracking.png',
    alt: 'Real-Time Inventory Tracking',
  },
  [BenefitType.ImprovedSalesEfficiency]: {
    src: '/images/our-benefits/improved-sales-efficiency.png',
    alt: 'Improved Sales Efficiency',
  },
  [BenefitType.EnhancedCustomerExperience]: {
    src: '/images/our-benefits/enhanced-customer-experience.png',
    alt: 'Enhanced Customer Experience',
  },
};
