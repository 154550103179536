import React from 'react';
import classNames from 'classnames';

export type ButtonSize = 'md' | 'lg';
export type ButtonType = 'primary' | 'secondary';

const buttonSizeClassNames: Record<ButtonSize, string> = {
  md: 'px-[1.125rem] py-3 text-xs',
  lg: 'px-8 py-4 text-lg',
};

const buttonTypeClassNames: Record<ButtonType, string> = {
  primary: 'bg-primary text-white',
  secondary: 'bg-background text-primary-80 border border-grey-10 shadow-sm',
};

const disabledClassName = 'cursor-not-allowed opacity-70';

export type ButtonProps = {
  onClick?: () => void;
  children: React.ReactNode;
  size?: ButtonSize;
  type?: ButtonType;
  className?: string;
  isFluid?: boolean;
  isDisabled?: boolean;
  htmlType?: 'button' | 'submit' | 'reset';
};
const Button: React.FC<ButtonProps> = ({
  children,
  className,
  size = 'md',
  type = 'primary',
  htmlType = 'button',
  isFluid,
  isDisabled,
  onClick,
}: ButtonProps) => {
  return (
    <button
      className={classNames(
        'font-bold flex items-center justify-center capitalize rounded-lg',
        buttonTypeClassNames[type],
        buttonSizeClassNames[size],
        isFluid && 'w-full',
        isDisabled && disabledClassName,
        className,
      )}
      onClick={onClick}
      type={htmlType}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};

export default Button;
