import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/core/Button/Button';
import RemoveIcon from 'assets/icons/Remove';
import Input from 'components/core/Input/Input';
import Textarea from 'components/core/Textarea/Textarea';
import { useSubmitGetStartedForm } from 'hooks/useSubmitGetStartedForm';
import { useGetStartedModalContext } from './GetStartedModalContext';
import { GetStartedFormData, getStartedFormSchema } from './schema';

const GetStartedModal = () => {
  const { setIsGetStartedModalOpen } = useGetStartedModalContext();
  const { postGetStartedForm, isLoading } = useSubmitGetStartedForm({
    closeModal: () => setIsGetStartedModalOpen(false),
  });

  const formHandler = useForm({
    defaultValues: {
      organizationName: '',
      contactPerson: '',
      email: '',
      text: '',
    },
    mode: 'all',
    resolver: yupResolver(getStartedFormSchema),
  });

  const { handleSubmit, formState, register } = formHandler;

  const onSubmit: SubmitHandler<GetStartedFormData> = (formData) => {
    postGetStartedForm({ formData });
  };

  return (
    <div className="fixed top-0 left-0 z-50 w-screen h-screen bg-[rgba(0,0,0,0.7)] flex justify-center items-center">
      <div className="p-8 bg-background border border-grey-10 rounded-3xl opacity-100 max-w-[31.25rem]">
        <div className="flex justify-end">
          <button
            onClick={() => setIsGetStartedModalOpen(false)}
            disabled={isLoading}
          >
            <RemoveIcon />
          </button>
        </div>
        <h2 className="text-2xl text-typography font-bold text-center max-w-[20rem] mx-auto">
          Get started now by filling out the form
        </h2>
        <form onSubmit={handleSubmit(onSubmit)} id="get-started-form">
          <Input
            name="organizationName"
            label="Organization name"
            wrapperClassName="mt-8"
            inputProps={{
              placeholder: 'Enter organization name',
              disabled: isLoading,
            }}
            error={formState?.errors?.organizationName?.message}
            register={register}
          />

          <Input
            name="contactPerson"
            label="Contact person"
            wrapperClassName="mt-4"
            error={formState?.errors?.contactPerson?.message}
            inputProps={{
              placeholder: 'Enter contact person',
              disabled: isLoading,
            }}
            register={register}
          />
          <Input
            name="email"
            label="Email address"
            wrapperClassName="mt-4"
            error={formState?.errors?.email?.message}
            inputProps={{
              placeholder: 'Enter email',
              disabled: isLoading,
            }}
            register={register}
          />
          <Textarea
            register={register}
            name="text"
            label="Text"
            textareaProps={{ placeholder: 'Enter text' }}
            error={formState?.errors?.text?.message}
          />
          <div className="mt-8 flex items-center justify-center">
            <Button
              type="primary"
              size="lg"
              isFluid
              htmlType="submit"
              isDisabled={isLoading}
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GetStartedModal;
