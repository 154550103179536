import React from 'react';

const NavbarLink = ({
  text,
  href,
  className,
}: {
  text: string;
  href: string;
  className?: string;
}) => {
  return (
    <li className={className}>
      <a className="text-base font-normal font-poppins" href={href}>
        {text}
      </a>
    </li>
  );
};

export default NavbarLink;
