import { createContext, useContext } from 'react';

export interface GetStartedModalContextProps {
  isGetStartedModalOpen: boolean;
  setIsGetStartedModalOpen: (isOpen: boolean) => void;
}

const GetStartedModalContext =
  createContext<GetStartedModalContextProps | null>(null);

export const useGetStartedModalContext = () => {
  const ctx = useContext(GetStartedModalContext);
  if (ctx == null) throw new Error('Get Started Modal context is not provided');
  return ctx;
};

export default GetStartedModalContext;
