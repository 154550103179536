import React from 'react';
import { PrivacySection } from 'utils/types';

interface PrivacySectionProps extends PrivacySection {
  index: number;
}

const PrivacyPolicySection = ({
  title,
  index,
  description,
  subSections,
}: PrivacySectionProps) => {
  return (
    <>
      <h4 className="text-2xl text-typography font-bold mt-16">
        {index}. {title}
      </h4>
      {description && (
        <p className="text-typography-80 text-lg font-normal mt-6">
          {description}
        </p>
      )}
      {subSections &&
        subSections.map((subSection, subSectionIndex) => (
          <div key={subSectionIndex}>
            <h6 className="text-xl text-typography font-bold mt-6">
              {index}.{subSectionIndex + 1} {subSection.subtitle}
            </h6>

            {subSection.description.length === 1 && (
              <p className="text-typography-80 text-lg font-normal mt-6">
                {subSection.description?.[0]}
              </p>
            )}

            {subSection.description.length > 1 && (
              <ul className="list-disc pl-4">
                {subSection.description.map(
                  (subSectionDescription, subSectionIndex) => (
                    <li key={subSectionIndex}>
                      <p className="text-typography-80 text-lg font-normal mt-6">
                        {subSectionDescription}
                      </p>
                    </li>
                  ),
                )}
              </ul>
            )}
          </div>
        ))}
    </>
  );
};

export default PrivacyPolicySection;
